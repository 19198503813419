import i18n from '@/i18n.js'
import store from '@/modules/Shared/Store/store'

const donutStatistics = ['tracked_distance_per_difficulty']
const translationsPrefix = {
  tracked_distance_per_difficulty: 'facilities.difficulty.'
}

const buildStatisticData = async (statisticKey, resourceType, resourceUuid = null) => {
  const statistics = store.getters['statistics/statistic'](statisticKey, resourceType, resourceUuid)

  if (statistics.length === 0)  {
    return null
  }

  const statistic = statistics[0];
  const translationKey = statistic.statistics[0] ? statistic.statistics[0].key : statistic.accumulateds[0].key
  const graphType = donutStatistics.includes(statisticKey) ? 'donut' : 'line';

  let units = statistic.statistics[0] ? statistic.statistics[0].units : statistic.accumulateds[0].units
  const nameUnits = units ? ` (${units})` : ''

  return {
    name: () => i18n.t(translationKey) + ` ${nameUnits}`,
    type: graphType,
    resource: {
      uuid: statistic.resourceUuid,
      type: statistic.resourceType,
      name: statistic.resourceName,
    },
    daily: generateGranularityStatistic(graphType, statistic.statistics, translationsPrefix[statisticKey]),
    season: generateGranularityStatistic(graphType, statistic.accumulateds, translationsPrefix[statisticKey]),
    total: getTotal(statistic.total),
  }
}

const generateGranularityStatistic = (graphType, statistics, translationPrefix = null) => {
  const current = statistics[0]
  if (!current) return null;
  const structure = {graph: null, card:  null}
  if (current.points.length === 1) {
    structure.card = getCardData(statistics)
  } else {
    structure.chart = getChartData(graphType, statistics, translationPrefix)
  }

  return structure
}

const getChartData = (graphType, statistics, translationPrefix) => {
  return {
    series: getChartSeries(graphType, statistics),
    options: getChartOptions(graphType, statistics, translationPrefix),
  }
}

const getChartSeries = (graphType, statistics) => {
  return graphType === 'line'
    ? getLineChartSeries(statistics)
    : getDonutChartSeries(statistics[0])
}

const getChartOptions = (graphType, statistics, translationPrefix) => {
  return graphType === 'line'
    ? getLineChartOptions(statistics, translationPrefix)
    : getDonutChartOptions(statistics[0], translationPrefix)
}

const getLineChartSeries = (statistics) => {
  if (!statistics[0]) return null

  return statistics
      .filter(statistic => statistic !== null)
      .reverse()
      .map(statistic => ({
          name: statistic.season,
          data: removeLeapYearDay(statistic.values),
      }))
}

const removeLeapYearDay = (data) => {
  if (data.length > 365) {
    // 59 is the index of 29th of february
    data.splice(59, 1);
  }
  return data
}

const getDonutChartSeries = (current) => {
  return current.values.map(value => parseFloat(value))
}

const getDonutChartOptions = (current, translationPrefix) => {

  const labels = current.points.map((point) => {
    const translationKey = translationPrefix ? `${translationPrefix}${point}` : point
    return i18n.t(translationKey)
  })

  return {
    yaxis: {
      labels: {
        formatter: function (value) {
          return formatValue(value);
        }
      }
    },
    labels: labels,
    colors: ['#09C6AF', '#406BDF', '#FA364A', '#333333'] // TODO: set slopes difficulty colors
  }
}

const getLineChartOptions = (statistics, translationPrefix) => {
  const current = statistics[0]
  if (!current) return null;

  const validStatistics = statistics.filter(statistic => statistic !== null)
  const categories = validStatistics[validStatistics.length - 1]
      .points
      .map((category) => {
          const translationKey = translationPrefix ? `${translationsPrefix}${category}` : category
          return i18n.t(translationKey)
      })

  let colors = ['#1B427A', '#9991EC', '#97E6ED', '#FFF5B3']

  return {
    chart: {
      type: 'line',
      height: 350,
      zoom: {
        enabled: true
      }
    },
    colors: colors.slice(0, validStatistics.length).reverse(),
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight',
      width: 2
    },
    title: {
      align: 'left'
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5
      }
    },
    xaxis: {
      type: 'datetime',
      categories: categories,
      labels: {
        format: 'dd/MM'
      }
    },
    yaxis: {
      title: {
        text: i18n.t(current.key)
      },
      labels: {
        formatter: function (value) {
          return formatValue(value);
        }
      }
    },
    legend: {
      showForSingleSeries: true,
      offsetY: 5,
      itemMargin: {
        vertical: 10
      },
    }
  }
}

const getCardData = (statistics) => {
  return statistics
      .filter(statistic => statistic !== null)
      .map(statistic => ({
          name: statistic.season,
          value: formatValue(statistic.values[0])
      }))
}

const getTotal = (total) => {
  return total ? formatValue(total.values[0]) : null
}

const formatValue = (value) => {

  const splittedValue = value.toString().split('.')
  const decimalCount = splittedValue.length === 1 ? 0 : splittedValue[1].length

  const options = {
    style: "decimal",
    minimumFractionDigits: decimalCount
  };

  value = new Intl.NumberFormat(navigator.language, options).format(parseFloat(value))

  return `${value}`.trim()
}

export {
  buildStatisticData
}
