<template>
  <div v-if="statisticData">
    <vx-card class="h-full no-shadow" :title="statisticData.name()" :subtitle="statisticData.resource.name">

      <div v-if="statisticData.total">
        <p class="font-bold mb-1 text-xl">{{ $t('statistics.total') }}</p>
        <div class="vx-row">
          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon-right
              no-icon
              :statistic="statisticData.total"/>
          </div>
        </div>
      </div>

      <div v-if="statisticData.season">
        <p class="font-bold mb-4">{{ $t('statistics.season') }}</p>

        <div v-if="statisticData.season.card" class="flex flex-row justify-start flex-wrap gap-8">
          <div class="statistic-card"
               v-for="(card, index) in statisticData.season.card" v-bind:key="index"
          >
            <statistics-card-line
              hideChart
              class=""
              icon-right
              no-icon
              :statistic="card.value"
              :statisticTitle="card.name" />
          </div>
        </div>

        <vue-apex-charts
          v-if="statisticData.season.chart"
          class="py-6"
          :type="statisticData.type"
          height="250"
          width="100%"
          :options="statisticData.season.chart.options"
          :series="statisticData.season.chart.series" />

      </div>


      <div v-if="statisticData.daily">
        <p class="font-bold mt-8">{{ $t('statistics.daily') }}</p>

        <div v-if="statisticData.daily.card" class="flex flex-row justify-start flex-wrap gap-8">
          <div class="statistic-card"
               v-for="(card, index) in statisticData.daily.card" v-bind:key="index"
          >
            <statistics-card-line
              hideChart
              class="mb-base"
              icon-right
              no-icon
              :statistic="card.value"
              :statisticTitle="card.name" />
          </div>
        </div>

        <vue-apex-charts ref="chart"
          v-if="statisticData.daily.chart"
          class="py-6"
          :type="statisticData.type"
          height="250"
          width="100%"

          :options="statisticData.daily.chart.options"
          :series="statisticData.daily.chart.series" />
      </div>

    </vx-card>
  </div>
</template>

<script>

  import VueApexCharts from 'vue-apexcharts'
  import StatisticsCardLine from '@/modules/Template/Components/statistics-cards/StatisticsCardLine.vue'
  import {buildStatisticData} from "../Services/StatisticsDataBuilder";

  export default {
    props: {
      statisticKey: String,
      resourceType: String,
      resourceUuid: String
    },
    data () {
      return {
        statisticData: null,
      }
    },
    components: {
      VueApexCharts,
      StatisticsCardLine
    },
    async created() {
      this.statisticData = await buildStatisticData(this.statisticKey, this.resourceType, this.resourceUuid)
      if (this.statisticData) {
        this.$emit('loaded')
      }
    },
  }
</script>

<style scoped>
  .statistic-card {
    min-width: 120px;
  }
</style>
